// @ts-ignore
import React, { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { saveAuth } from './redux/auth/authActions';
import ProtectedRoute from './utils/ProtectedRoute';
import 'App.css';
import './assets/scss/style.scss';
import './assets/scss/main.scss';
import Notification from './components/notification/Notification';
import { auth } from './configs/databaseConfig';
import { migration, translationsMigration } from './migrations';
import { selectDatabase } from './redux/firebase/Selector';
import { getUser } from './redux/users/usersActions';
import { selectAuth } from '../src/redux/auth/Selector';

function App() {
  const dispatch = useDispatch();
  const database = useSelector(selectDatabase);
  const use = useSelector(selectAuth);

  useEffect(() => {
    database && migration(database);
    database && translationsMigration(database);
  }, [database]);
  console.log(use, 'use');
  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        // localStorage.setItem('user', user.uid);
        dispatch(getUser(user.uid));
        dispatch(saveAuth(user));
      } else {
        // localStorage.removeItem('user');
        dispatch(saveAuth(undefined));
        console.log('user log out');
      }
    });
  }, [auth, dispatch]);

  return (
    <div>
      <ProtectedRoute />
      <Notification />
    </div>
  );
}

export default App;
