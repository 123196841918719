import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';

function Sidebar(props) {
  const { inputs, labels } = props;
  const titles = labels && labels.data && JSON.parse(labels.data);
  let sidebarItem = [];
  if (inputs) {
    sidebarItem = inputs.filter(input => input.component === 'Description' && input?.role !== 'title');
  }
  const [isOpen, setIsOpen] = useState(false);
  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  const scroll = e => {
    const element = document.getElementById(e);
    element.scrollIntoView({ block: 'center' });
  };

  return (
    <div className="scroll-sidebar">
      {sidebarItem.length !== 0 && (
        <div>
          <MenuIcon onClick={toggleOpen} className={`closeSidebar ${!isOpen && 'openSidebar'}`} />
          {isOpen && (
            <div className="scroll-sidebar-content">
              {sidebarItem.map(item => {
                const name = item.name;
                return (
                  <>
                    {titles[`${name}_subTitle`] && (
                      <p key={titles[`${name}_subTitle`]} onClick={() => scroll(name)} className="scroll-item">
                        {titles[`${name}_subTitle`]}
                      </p>
                    )}
                    {titles[`${name}_subSubTitle`] && (
                      <p key={titles[`${name}_subSubTitle`]} onClick={() => scroll(name)} className="scroll-item">
                        {titles[`${name}_subSubTitle`]}
                      </p>
                    )}
                    <br />
                  </>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Sidebar;
