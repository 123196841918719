import { CURRENT_TAB, CURRENT_FORMS, GET_FORMS } from './Types';

export default function (state = {}, action) {
  switch (action.type) {
    case GET_FORMS:
      return {
        ...state,
        forms: action.payload,
      };
    case CURRENT_FORMS:
      return { ...state, currentForm: action.payload };
    case CURRENT_TAB:
      return { ...state, currentTab: action.payload };
    default:
      return state;
  }
}
