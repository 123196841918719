import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InfoIcon from '@mui/icons-material/Info';
import Information from './Information';

function DatePicker(props) {
  const {
    input,
    field,
    meta: { touched, error, warning },
  } = props;
  const change = e => {
    input.onChange(e);
  };
  return (
    <div className={`mb-3 ${field.stylePageBreak && field.stylePageBreak}`}>
      <div className="d-flex flex-direction-row justify-content-around write-answer">
        {field.info && (
          <div>
            <InfoIcon className="info-icon" />
            <Information info={field.info} />
          </div>
        )}
        {field.description && <p className="mr-3">{field.description} </p>}
        <TextField
          name={input.name}
          label={field.label}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={input.value}
          onChange={change}
        />
        {touched &&
          ((error && <p className="help-block">{error}</p>) || (warning && <p className="help-block">{warning}</p>))}
      </div>
    </div>
  );
}
DatePicker.propTypes = {
  input: PropTypes.object,
  field: PropTypes.object,
  meta: PropTypes.object,
  value: PropTypes.string,
};

export default DatePicker;
