import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import { logOut } from '../../pages/auth/LogOut';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/scss/login.scss';
import LogoutIcon from '@mui/icons-material/Logout';
import { signOut } from 'firebase/auth';
import { auth } from '../../../src/configs/databaseConfig';
import { useNavigate } from 'react-router';
import { selectAuth } from '../../../src/redux/auth/Selector';

export default function SimpleMenu() {
  const use = useSelector(selectAuth);

  console.log('this is comming from MENU.JSX', use);
  const [logout, setLogOut] = useState(false);
  console.log('logout ', logout);
  if (logout) {
    const navigate = useNavigate();
    navigate('/login');
  }

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    localStorage.removeItem('user');
  };

  const signOutt = async () => {
    await signOut(auth);
    console.log('does it get here?');
    logOut();
  };

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {t('openMenu')}
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <Link className="log-out" to="#" onClick={signOutt}>
            {t('logOut')}
            <LogoutIcon className="log-out-icon" />
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}
