import React from 'react';
import PropTypes from 'prop-types';
function TextArea(props) {
  const {
    input,
    field,
    titles,
    meta: { touched, error, warning },
  } = props;
  const name = input.name;
  return (
    <div className={`mb-3 ${field.stylePageBreak && field.stylePageBreak}`}>
      <div className="d-flex flex-direction-row justify-content-around write-answer">
        {titles[`${name}_header`] && <p className="mr-4">{titles[`${name}_header`]} </p>}
        {titles[`${name}_description`] && <p className="mr-3">{titles[`${name}_description`]} </p>}
        <textarea {...input} value={input.value} placeholder={titles[`${name}_label`]} className={field.style} />

        {touched &&
          ((error && <p className="help-block">{error}</p>) || (warning && <p className="help-block">{warning}</p>))}
      </div>
    </div>
  );
}
TextArea.propTypes = {
  input: PropTypes.object,
  field: PropTypes.object,
  meta: PropTypes.object,
  value: PropTypes.string,
};

export default TextArea;
