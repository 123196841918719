import { CREATE_AUTH } from './Types';

export const saveAuth = auth => {
  return async dispatch => {
    dispatch({
      type: CREATE_AUTH,
      payload: auth,
    });
  };
};
