import { GET_FORMS, CURRENT_TAB, CURRENT_FORMS } from './Types';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';

export function getForms() {
  return async (dispatch, getState) => {
    const database = getState().firebase.database;
    // @ts-ignore
    const projectsRef = collection(database, 'forms');
    const data = query(projectsRef, orderBy('order'));

    const querySnapshot = await getDocs(data);
    const events = [];

    querySnapshot.forEach(doc => events.push({ ...doc.data(), id: doc.id }));
    return dispatch({ type: GET_FORMS, payload: events });
  };
}

export const setCurrentTab = tab => {
  return async dispatch => {
    dispatch({ type: CURRENT_FORMS, payload: tab });
    dispatch({ type: CURRENT_TAB, payload: tab });
  };
};
