import * as Yup from 'yup';

export const loginYup = {
  username: Yup.string().required(),
  password: Yup.string().required().min(6, 'Password must be at least 6 characters'),
  passPhrase: Yup.string().required().min(6, 'passPhrase must be at least 6 characters'),
};

export const confirmPasswordYup = {
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
};

export const fierbaseYup = {
  apiKey: Yup.string().required('apiKey Password is required'),
  projectId: Yup.string().required('projectId Password is required'),
  messagingSenderId: Yup.string().required('messagingSenderId Password is required'),
  appId: Yup.string().required('appId Password is required'),
  passPhrase: Yup.string().required('passPhrase is required').min(6, 'passPhrase must be at least 6 characters'),
  confirmPassPhrase: Yup.string()
    .required('Confirm PassPhrase is required')
    .oneOf([Yup.ref('passPhrase')], 'passPhrase must match'),
};
