import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../components/shared/Footer';
import FormTabs from '../../components/forms/FormTabs';
import { getProject, getProjects } from '../../redux/project/projectAction';
import { selectProjects } from '../../redux/project/Selector';
import { selectDatabase } from '../../redux/firebase/Selector';
const Projects = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const projects: any[] = useSelector(selectProjects);
  const database = useSelector(selectDatabase);

  useEffect(() => {
    // @ts-ignore
    dispatch(getProject(params.id));
  }, [params, database]);

  useEffect(() => {
    if (!projects) {
      dispatch(getProjects());
    }
  }, [database]);

  return (
    <div>
      <FormTabs />
      <Footer />
    </div>
  );
};

export default Projects;
