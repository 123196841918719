import React from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

const VisibilityPass = ({ handleClick }) => {
  return (
    <>
      <IconButton className="visibilityButton" onClick={handleClick}>
        {handleClick ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </>
  );
};

export default VisibilityPass;
