import { CREATE_FIERBASE } from './Types';
import { secrets } from '../../configs/secrets';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

export const addFierbase = fierbase => {
  return async dispatch => {
    dispatch({
      type: CREATE_FIERBASE,
      payload: fierbase,
    });
  };
};

export const decodeConfigs = cipher => {
  return async (dispatch, getState) => {
    const userConfig = getState().userConfigs.userConfigs;
    const projectId = secrets.decode(cipher, userConfig.projectId);
    const configs = {
      apiKey: secrets.decode(cipher, userConfig.apiKey),
      projectId,
      authDomain: `${projectId}.firebaseapp.com`,
      databaseURL: `https://${projectId}-default-rtdb.asia-southeast1.firebasedatabase.app`,
      storageBucket: `${projectId}.appspot.com`,
      messagingSenderId: secrets.decode(cipher, userConfig.messagingSenderId),
      appId: secrets.decode(cipher, userConfig.appId),
    };
    const databaseConfig = initializeApp(configs, userConfig.projectId);
    const database = getFirestore(databaseConfig);

    const storage = getStorage(databaseConfig);

    dispatch({
      type: CREATE_FIERBASE,
      databaseConfig: configs,
      database: database,
      storage: storage,
    });
  };
};
