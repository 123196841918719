import React from 'react';
import PropTypes from 'prop-types';
// or
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
//halpers
import { UseSvgIcon } from 'helpers/UseSvgIcon';

import Select from 'react-select';
import 'quill/dist/quill.snow.css';

export function TextFieldIcon({ input, label, type, icon, defaultValue, value, meta: { touched, error, warning } }) {
  return (
    <div className="mb-3">
      <div>
        <label>
          {/*<CIcon name={icon} />*/}
          <i className={`fa ${icon}`} aria-hidden="true">
            {' '}
          </i>
        </label>
      </div>
      <input
        {...input}
        className={touched && error ? 'form-control-warning' : ''}
        placeholder={label}
        type={type}
        defaultValue={defaultValue}
        value={value}
      />
      {touched &&
        ((error && <p className="help-block">{error}</p>) || (warning && <p className="help-block">{warning}</p>))}
    </div>
  );
}

TextFieldIcon.propTypes = {
  input: PropTypes.object,
  field: PropTypes.object,
  meta: PropTypes.object,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
};

export function Boolean({ input, field, meta: { touched, error, warning } }) {
  return (
    <div className={` boolean ${field.class}`}>
      <label htmlFor="inputWarning2i">{field.label} </label>
      <input type="radio" {...input} className={'mx-1'} color={'primary'} defaultChecked={input.value} />

      {touched &&
        ((error && <p className="help-block">{error}</p>) || (warning && <p className="help-block">{warning}</p>))}
    </div>
  );
}

Boolean.propTypes = {
  input: PropTypes.object,
  field: PropTypes.object,
  meta: PropTypes.object,
};

export function TextInput({ input, field, meta: { touched, error } }) {
  const [labelWidth, setLabelWidth] = React.useState(0);
  const labelRef = React.useRef(null);
  React.useEffect(() => {
    labelRef && setLabelWidth(labelRef.current.offsetWidth);
  }, [labelRef]);

  return (
    <FormControl className={`input-field ${field.class}`} variant="outlined" error={!!(touched && error)}>
      <div className="input-bg">
        <InputLabel ref={labelRef} htmlFor={field.name}>
          {field.label}
        </InputLabel>
        <OutlinedInput
          {...input}
          id={field.name}
          type={field.type || 'text'}
          labelWidth={labelWidth}
          endAdornment={field.icon && <InputAdornment position="end">{UseSvgIcon(field.icon)}</InputAdornment>}
        />
      </div>
      {error && touched && <FormHelperText id="component-error-text">{error}</FormHelperText>}
      {/* <TextField
      // defaultValue={defaultValue}
      // value={value || defaultValue}
      // value={value || defaultValue}
      {...input}

      // defaultValue={defaultValue}
      invalid={!!(touched && error)}
      label={field.label}
      variant="outlined"
      id="my-input"
      required={error}
      error={touched && error}
      helperText={touched && error}
      type={field.type}
    /> */}
      {/*{touched &&*/}
      {/*((error && <FormHelperText className="error">*/}
      {/*    {error}*/}
      {/*  </FormHelperText>) ||*/}
      {/*  (warning && <FormHelperText className="warning">*/}
      {/*      {warning}*/}
      {/*    </FormHelperText>*/}

      {/*  ))}*/}
    </FormControl>
  );
}
TextInput.propTypes = {
  input: PropTypes.object,
  field: PropTypes.object,
  meta: PropTypes.object,
};

export function textareaField({ input, field, meta: { touched, error, warning } }) {
  return (
    <div className="mb-3 multiline">
      {/*<CLabel htmlFor="inputWarning2i">{field.label}</CLabel>*/}
      {/*<textarea {...input}*/}
      {/*  defaultValue={defaultValue}/>*/}
      <TextField {...input} id="outlined-multiline-static" label={field.label} multiline rows={1} variant="outlined" />
      {field.icon && UseSvgIcon(field.icon)}
      {touched &&
        ((error && <p className="help-block">{error}</p>) || (warning && <p className="help-block">{warning}</p>))}
    </div>
  );
}
textareaField.propTypes = {
  input: PropTypes.object,
  field: PropTypes.object,
  meta: PropTypes.object,
};

export function select({ input, field, options, meta: { touched, error, warning } }) {
  const blur = () => {};
  return (
    <div className="mb-3">
      <label htmlFor="inputWarning2i">{field.label}</label>
      <Select {...input} name="form-field-name2" options={options} isMulti={field.multi} onBlur={blur} />

      {touched &&
        ((error && <p className="help-block">{error}</p>) || (warning && <p className="help-block">{warning}</p>))}
    </div>
  );
}
select.propTypes = {
  input: PropTypes.object,
  field: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.array,
};
