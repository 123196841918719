import React, { useState } from 'react';
import Modal from 'react-modal';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { addProject, saveProject } from '../../redux/project/projectAction';
import { useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';

const ProjectModal = ({ isOpen, toggleModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [description, setdescription] = useState('');

  const handleProject = () => {
    if (name === '' || description === '') {
      const error = { code: t('fillError') };
      dispatch({ type: 'ADD_NOTIFICATION', payload: error });
      return;
    }
    dispatch(addProject({ name: name, description: description }));
    dispatch(saveProject());
    const success = { code: t('successMessage') };
    dispatch({ type: 'ADD_NOTIFICATION', payload: success });
    toggleModal();
    setName('');
    setdescription('');
  };

  const changeName = e => {
    setName(e.target.value);
  };

  const changeDescription = e => {
    setdescription(e.target.value);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={toggleModal} contentLabel="My dialog">
      <div className="mb-4">
        <h3>{t('createProject')}</h3>
      </div>
      <div className="form-group first field--not-empty">
        <InputLabel htmlFor="name">{t('projectName')}</InputLabel>
        <Input value={name} id="name" type="text" className="form-control" onChange={changeName} />
      </div>
      <div className="form-group last mb-4 field--not-empty">
        <InputLabel htmlFor="password">{t('projectDescription')}</InputLabel>
        <Input value={description} id="description" type="text" className="form-control" onChange={changeDescription} />
      </div>
      <button onClick={handleProject} className="btn text-white btn-block btn-green ">
        {t('createProject')}
      </button>
      <IconButton onClick={toggleModal} className="modal-close-btn">
        <CloseIcon />
      </IconButton>
    </Modal>
  );
};

export default ProjectModal;
