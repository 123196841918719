import React from 'react';
import { connect } from 'react-redux';
import { FormSpy } from 'react-final-form';
import { updateFormState } from './Finalform';
import PropTypes from 'prop-types';

const FormStateToRedux = ({ form, updateFormState }) => <FormSpy onChange={state => updateFormState(form, state)} />;

FormStateToRedux.propTypes = {
  form: PropTypes.string,
  updateFormState: PropTypes.func,
};

export default connect(undefined, { updateFormState })(FormStateToRedux);
