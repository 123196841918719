// @ts-ignore
import React, { useState, useEffect } from 'react';
import '../../assets/scss/login.scss';
import { getProjects, setCurrentProject } from '../../redux/project/projectAction';
import { selectProjects, selectCurrentProject } from '../../redux/project/Selector';
import { selectDatabase } from '../../redux/firebase/Selector';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { DataGrid } from '@mui/x-data-grid';
import ProjectModal from './ProjectModal';
import DeleteProjectConfirm from './DeleteProjectConfirm';
import { useTranslation } from 'react-i18next';

const Projects = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const projects: any[] = useSelector(selectProjects);
  const current: any = useSelector(selectCurrentProject);
  const database = useSelector(selectDatabase);

  const [isOpen, setIsOpen] = useState(false);
  const [confirmDelete, setconfirmDelete] = useState(false);
  const [projectId, setProjectId] = useState(null);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  function toggleDeleteModal() {
    setconfirmDelete(!confirmDelete);
  }

  const deleteProject = e => {
    setProjectId(e);
    toggleDeleteModal();
    // dispatch(removeProject(e));
  };

  const setCurrent = e => {
    // @ts-ignore

    if (current?.id === e.id) {
      dispatch(setCurrentProject(null));
    } else {
      dispatch(setCurrentProject(e));
    }
  };

  useEffect(() => {
    if (!projects) {
      dispatch(getProjects());
    }
    console.log(projects);
  }, [database]);

  const columns = [
    {
      field: 'name',
      headerName: <span>{t('name')}</span>,
      width: 150,
      editable: true,
      renderCell: params => (
        <Link
          to={`/project/${params.id}`}
          onClick={() => setCurrent(params)}
          className={`project ${current?.id === params.id ? 'current' : ''}`}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'description',
      headerName: <span>{t('description')}</span>,
      width: 150,
      editable: true,
    },
    {
      field: 'userId',
      headerName: <span>{t('createdBy')}</span>,
      width: 110,
      editable: true,
    },
    {
      field: 'date',
      headerName: <span>{t('dateCreated')}</span>,
      width: 110,
      type: 'date',
      editable: true,
      valueFormatter: params => {
        if (params.value) {
          return new Date(params.value.seconds * 1000).toLocaleDateString('en-US');
        }
      },
    },
    {
      field: 'action',
      headerName: <span>{t('action')}</span>,
      sortable: false,
      renderCell: params => {
        return (
          <div>
            <IconButton edge="end" onClick={() => deleteProject(params.id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const rows = projects ? projects : [];

  return (
    <div className="form-main-content">
      <div className="create-projects">
        <button onClick={toggleModal} className="btn text-white btn-block btn-green ">
          {t('createProject')}
        </button>
      </div>
      <div>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={rows}
            //  @ts-ignore
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
          />
        </div>
      </div>
      <ProjectModal isOpen={isOpen} toggleModal={toggleModal} />
      <DeleteProjectConfirm confirmDelete={confirmDelete} toggleDeleteModal={toggleDeleteModal} projectId={projectId} />
    </div>
  );
};

export default Projects;
