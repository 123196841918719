// import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';

function FieldItem({ item, register, errors }) {
  const { t } = useTranslation();
  const itemValue = item.value;

  return (
    <div className="form-group first field--not-empty register-form">
      <InputLabel>{item.label}</InputLabel>
      <Input
        name={item.value}
        id={item.value}
        type={item.type || 'text'}
        className="form-control"
        {...register(item.value, { required: true })}
        // ref={item.value}
      />
      {errors[item.value] && <span className="error-text">{t('passwordsDontMatch')}</span>}
    </div>
  );
}
export default FieldItem;
