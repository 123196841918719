// @ts-ignore
import React, { useState, useEffect } from 'react';
import { uploadBytesResumable, ref, getDownloadURL } from 'firebase/storage';
import { useDispatch, useSelector } from 'react-redux';
import { saveFile, getFormUploads } from '../../redux/uploads/uploadsActions';
import { selectStorage } from '../../redux/firebase/Selector';
import InfoIcon from '@mui/icons-material/Info';
function FileUpload(props) {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const storage = useSelector(selectStorage);
  let result = [];

  const {
    input,
    field,
    meta: { touched, error, warning },
  } = props;

  const [uploads, setUploads] = useState(input.value || []);

  useEffect(() => {
    setUploads(input.value);
  }, [input]);

  result = [...input.value];
  const uploadFiles = e => {
    e.preventDefault();
    const files = e.target.files;
    saveFiles(files);
  };

  const change = e => {
    result.push(e);
    input.onChange(result);
    setUploads([...result]);
  };

  const saveFiles = files => {
    if (!files) return;

    for (let i = 0; i < files.length; i++) {
      const storageRef = ref(storage, `/files/${files[i].name}`);
      const uploadTask = uploadBytesResumable(storageRef, files[i]);
      uploadTask.on(
        'state_changed',
        snapshot => {
          const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(prog);
        },
        err => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(url => {
            const newFile = { fileName: files[i].name, fileUrl: url };
            change(newFile);
            dispatch(saveFile(newFile, field.name));
          });
        }
      );
    }
  };

  useEffect(() => {
    dispatch(getFormUploads());
  }, []);

  return (
    <div className={`mb-0 ${field.stylePageBreak && field.stylePageBreak}`}>
      <div className="d-flex flex-direction-row justify-content-around radio-options">
        {field.info && <InfoIcon className="info-icon" />}
        {field.label && <p className="mr-3">{field.label} </p>}

        <div>
          <input
            type="file"
            multiple
            onChange={e => {
              uploadFiles(e);
            }}
          />
          {uploads.length !== 0 &&
            uploads.map(file => {
              return (
                <div key={file.fileName}>
                  <a className="loading-file" href={file.fileUrl}>
                    {file.fileName}
                  </a>
                </div>
              );
            })}
        </div>
        {touched &&
          ((error && <p className="help-block">{error}</p>) || (warning && <p className="help-block">{warning}</p>))}
      </div>
    </div>
  );
}

export default FileUpload;
