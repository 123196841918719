import { secrets } from '../configs/secrets';

export const encodeCredentials = data => {
  return {
    apiKey: secrets.encode(data.passPhrase, data.apiKey),
    projectId: secrets.encode(data.passPhrase, data.projectId),
    messagingSenderId: secrets.encode(data.passPhrase, data.messagingSenderId),
    appId: secrets.encode(data.passPhrase, data.appId),
  };
};
