import { SAVE_ANSWER, INITIAL_VALUES } from './Types';

export default function (state = { initialValues: {} }, action) {
  switch (action.type) {
    case SAVE_ANSWER:
      return { ...state };
    case INITIAL_VALUES:
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          [action.id]: action.payload,
        },
      };
    default:
      return state;
  }
}
