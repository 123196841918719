import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import SimpleMenu from './Menu';
import { Link } from 'react-router-dom';
import Language from './Language';
import { useTranslation } from 'react-i18next';
import { selectAuth } from '../../../src/redux/auth/Selector';
import { useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';

import { auth } from '../../../src/configs/databaseConfig';

const Header = () => {
  const [currUser, setCurrUser] = useState({});
  useEffect(() => {
    onAuthStateChanged(auth, currentUser => {
      setCurrUser(currentUser);
    });
  }, []);

  const { t } = useTranslation();
  // const user = localStorage.getItem('user');
  const user = useSelector(selectAuth);
  // const lang = localStorage.getItem('');
  return (
    <AppBar position="sticky" className="header">
      <Toolbar>
        <Typography variant="h6" color="inherit" noWrap>
          <Link to={currUser ? '/' : '/login'} className="logo-link">
            Audit Software
          </Link>
        </Typography>
        <div className="grow" />
        <a
          className="header-link"
          target="_blank"
          rel="noreferrer"
          href="https://caaa.am/wp-content/uploads/2022/10/ASFS_final_ARM-1.pdf"
        >
          {' '}
          {t('guide')}
        </a>
        <Language />
        {user && <SimpleMenu />}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
