import { authDatabase } from '../../configs/databaseConfig';
import { setDoc, getDoc, doc, updateDoc } from 'firebase/firestore';
import { UPDATE_USER_CONFIGS, DELETE_USER_CONFIGS } from './Types';
import { auth } from '../../configs/databaseConfig';

export const addUser = userConfigs => {
  return async dispatch => {
    dispatch({
      type: UPDATE_USER_CONFIGS,
      payload: userConfigs,
    });
  };
};

export const createUser = secret => {
  return async dispatch => {
    try {
      const docRef = await setDoc(doc(authDatabase, 'users', auth.currentUser.uid), {
        apiKey: secret.apiKey,
        projectId: secret.projectId,
        messagingSenderId: secret.messagingSenderId,
        appId: secret.appId,
      });
    } catch (e) {
      console.log(e);
      dispatch({ type: 'ADD_NOTIFICATION', payload: e });
    }
    dispatch({ type: UPDATE_USER_CONFIGS, payload: secret });
  };
};

export const getUser = uid => {
  return async dispatch => {
    const docRef = doc(authDatabase, 'users', uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return dispatch({ type: UPDATE_USER_CONFIGS, payload: { ...docSnap.data() } });
    } else {
      console.log('No such document!');
    }
  };
};

export const deleteUserConfigs = userId => {
  return async dispatch => {
    // const database = getState().firebase.database;
    // deleteDoc(doc(database, 'projects', projectId));
    // const user = getState().auth.auth.uid

    return dispatch({
      type: DELETE_USER_CONFIGS,
    });
  };
};

export const updateUser = (userConfigs, uid) => {
  return async dispatch => {
    const userData = doc(authDatabase, 'users', uid);
    await updateDoc(userData, {
      apiKey: userConfigs.apiKey,
      appId: userConfigs.appId,
      messagingSenderId: userConfigs.messagingSenderId,
      projectId: userConfigs.projectId,
    });

    dispatch({ type: UPDATE_USER_CONFIGS, payload: userConfigs });
  };
};
