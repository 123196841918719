export const secrets = (function () {
  'use strict';

  function decode(key, cipher) {
    // Primary decode operation
    let arrKey = key.split('');
    let arrCipher = cipher.split('');
    let arrKeyNum = uniEncode(arrKey);
    let arrCipherNum = uniEncode(arrCipher);
    let arrKeystream = [];
    for (let i = 0; i < arrCipherNum.length; i++) {
      let mult = returnInt(i / arrKeyNum.length);
      let pos = i % arrKeyNum.length;
      arrKeystream.push(arrKeyNum[pos] + mult);
    }
    let arrTextNum = [];
    for (let j = 0; j < arrCipherNum.length; j++) {
      arrTextNum.push((arrCipherNum[j] + 65536 - arrKeystream[j]) % 65536);
    }
    let arrText = uniDecode(arrTextNum);
    let text = arrText.join('');
    return text;
  }

  function encode(key, text) {
    // Primary encode operation
    let arrKey = key.split('');
    let arrText = text.split('');
    let arrKeyNum = uniEncode(arrKey);
    let arrTextNum = uniEncode(arrText);
    let arrKeystream = [];
    for (let i = 0; i < arrTextNum.length; i++) {
      let mult = returnInt(i / arrKeyNum.length);
      let pos = i % arrKeyNum.length;
      arrKeystream.push(arrKeyNum[pos] + mult);
    }
    let arrCipherNum = [];
    for (let j = 0; j < arrTextNum.length; j++) {
      arrCipherNum.push((arrTextNum[j] + arrKeystream[j]) % 65536);
    }
    let arrCipher = uniDecode(arrCipherNum);
    let cipher = arrCipher.join('');
    return cipher;
  }

  function returnInt(n) {
    // Staple function
    return parseInt(n, 10);
  }

  function uniDecode(arr) {
    // Maps an array of Unicode values to their UTF-8 characters
    return arr.map(function (x) {
      return String.fromCharCode(x);
    });
  }

  function uniEncode(arr) {
    // Maps an array of UTF-8 characters to their Unicode values
    return arr.map(function (x) {
      return x.charCodeAt(0);
    });
  }

  // Public ----------

  return {
    decode: decode,
    encode: encode,
    uniDecode: uniDecode,
    uniEncode: uniEncode,
  };
})();
