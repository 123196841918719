// @ts-ignore
import React, { useState, useEffect } from 'react';

function DynamicTable(props) {
  const { input, field, titles } = props;
  const name = input.name;
  const options = field.props;
  const values = input.value || [];
  const bodyLength = options.body ? options.body.length : 0;
  const [rowCount, setRowCount] = useState(options.rowCount || 1);
  const onChange = (e, i, key) => {
    const newRowDate = [...values];
    newRowDate[i] = newRowDate[i] || {};
    newRowDate[i][key.value] = key.type == 'checkbox' ? e.target.checked : e.target.value;
    input.onChange(newRowDate);
  };
  useEffect(() => {
    values?.length && values.length > rowCount + bodyLength && setRowCount(values.length - 1);
  }, [values]);

  const addRow = () => {
    setRowCount(rowCount + 1);
  };
  const removeRow = i => {
    const newRowDate = [...values];
    newRowDate.splice(i + bodyLength, 1);
    input.onChange(newRowDate);
    setRowCount(rowCount - 1);
  };

  return (
    <div className={`mb-3 ${field.stylePageBreak && field.stylePageBreak}`}>
      <div className="d-flex flex-direction-row justify-content-around write-answer">
        {titles[`${name}_header`] && <p className="mr-4">{titles[`${name}_header`]} </p>}
        {titles[`${name}_description`] && <p className="mr-3">{titles[`${name}_description`]} </p>}
        <table className="table">
          <tr>
            {options.head &&
              options.head.map((column, i) => {
                return (
                  <th className="table-fields" key={i}>
                    <span className="table-names">{titles[column.label]}</span>
                  </th>
                );
              })}
          </tr>
          <tbody>
            {options.body &&
              options.body.map((row, i) => {
                return (
                  <tr key={i} className="table-row">
                    {options.head &&
                      options.head.map((column, j) => {
                        if (row[column.value]) {
                          if (column.type == 'checkbox') {
                            const checked = values[i]?.[column.value];
                            return (
                              <td key={j} className="dynamic-inputs">
                                <input type="checkbox" checked={checked || ''} onChange={e => onChange(e, i, column)} />
                              </td>
                            );
                          }
                          return (
                            <td key={j} className="static-inputs">
                              <div className="table-text">{titles[row[column.value]]}</div>
                            </td>
                          );
                        } else {
                          const value = values[i]?.[column.value];
                          return (
                            <td key={j} className="static-inputs">
                              <textarea
                                name={`${column.value}${i}`}
                                value={value || ''}
                                className="static-fields"
                                onChange={e => onChange(e, i, column)}
                              />
                            </td>
                          );
                        }
                      })}
                  </tr>
                );
              })}
            {Array(rowCount)
              .fill('')
              .map((row, i) => {
                return (
                  <tr key={i + bodyLength} className="table-row">
                    {options.head &&
                      options.head.map((column, j) => {
                        if (j == 0) {
                          if (column.type == 'checkbox') {
                            const checked = values[i + bodyLength]?.[column.value];
                            return (
                              <td key={column.value} className="dynamic-inputs">
                                <input
                                  type="checkbox"
                                  checked={checked || ''}
                                  onChange={e => onChange(e, i + bodyLength, column)}
                                />
                                <div className="delete-icon-div">
                                  <button type="button" onClick={() => removeRow(i)} className="delete-icon">
                                    -
                                  </button>
                                </div>
                              </td>
                            );
                          }
                          const value = values[i + bodyLength]?.[column.value];
                          return (
                            <td key={column.value} className="dynamic-inputs">
                              <textarea
                                name={`${column.value}${i}`}
                                value={value || ''}
                                className="dynamic-fields"
                                onChange={e => onChange(e, i + bodyLength, column)}
                              />
                              <div className="delete-icon-div">
                                <button type="button" onClick={() => removeRow(i)} className="delete-icon">
                                  -
                                </button>
                              </div>
                            </td>
                          );
                        }
                        if (column.type == 'checkbox') {
                          const checked = values[i + bodyLength]?.[column.value];
                          return (
                            <td key={column.value} className="dynamic-inputs">
                              <input
                                type="checkbox"
                                checked={checked || ''}
                                onChange={e => onChange(e, i + bodyLength, column)}
                              />
                            </td>
                          );
                        }
                        const value = values[i + bodyLength]?.[column.value];
                        return (
                          <td key={column.value} className="dynamic-inputs">
                            <textarea
                              name={`${column.value}${i}`}
                              value={value || ''}
                              className="dynamic-fields"
                              onChange={e => onChange(e, i + bodyLength, column)}
                            />
                          </td>
                        );
                      })}
                  </tr>
                );
              })}
          </tbody>
        </table>

        <div className="buttons">
          <button type="button" onClick={addRow}>
            +
          </button>
        </div>
        {field.label && <textarea {...input} value={input.value} placeholder={field.label} className={field.style} />}
      </div>
    </div>
  );
}

export default DynamicTable;
