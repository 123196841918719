import { applyMiddleware, combineReducers, createStore } from 'redux';
import authReducer from './auth/authReduser';
import projectReducer from './project/projectReducer';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import finalFormReducer from './form/Finalform';
import forms from './forms/FormsReducer';
import answers from './answers/AnswersRedducer';
import { Notification } from './notifications/NotificationReducer';
import uploadsReducer from './uploads/uploadsReduser';
import usersReducer from './users/usersReduser';
import firebaseReducer from './firebase/firebaseReduser';
import TranslationsReducer from './translations/TranslationsReducer';
const store = createStore(
  combineReducers({
    auth: authReducer,
    project: projectReducer,
    form: finalFormReducer,
    forms: forms,
    notification: Notification,
    answers: answers,
    uploads: uploadsReducer,
    userConfigs: usersReducer,
    firebase: firebaseReducer,
    translations: TranslationsReducer,
  }),
  composeWithDevTools(applyMiddleware(thunk))
);

export { store };
