import { SAVE_ANSWER, INITIAL_VALUES } from './Types';
import { collection, query, where, getDocs, setDoc, doc } from 'firebase/firestore';
// import { auth } from '../../configs/databaseConfig';

export function getAnswers() {
  return async (dispatch, getState) => {
    const database = getState().firebase.database;
    // @ts-ignore
    const projectsRef = collection(database, 'answers');

    const data = query(projectsRef, where('userId', '==', getState().auth.auth.uid));

    const querySnapshot = await getDocs(data);
    querySnapshot.forEach(doc => {
      console.log(doc.id, ' => ', doc.data());
    });
  };
}

export function getProjectAnswer() {
  return async (dispatch, getState) => {
    const database = getState().firebase.database;
    const state = getState();
    const project = state.project.current;
    const currentForm = state.forms.currentForm;

    const projectsRef = collection(database, 'answers');
    try {
      const data = query(
        projectsRef,
        where('projectId', '==', project.id),
        where('userId', '==', getState().auth.auth.uid),
        where('formId', '==', currentForm.id)
      );
      const querySnapshot = await getDocs(data);

      const events = [];
      querySnapshot.forEach(doc => events.push({ ...doc.data(), id: doc.id }));
      return dispatch({ type: INITIAL_VALUES, payload: events, id: currentForm.id });
    } catch (e) {
      dispatch({ type: 'ADD_NOTIFICATION', payload: e });
    }
  };
}

export const saveAnswers = id => {
  return async (dispatch, getState) => {
    const database = getState().firebase.database;
    const state = getState();
    const exampleForm = state.form[id].values;
    const project = state.project.current;
    const currentForm = state.forms.currentForm;
    const jsonString = JSON.stringify(exampleForm);

    const newId = `${getState().auth.auth.uid}-${project.id}-${currentForm.id}`;
    try {
      const answersRef = collection(database, 'answers');
      await setDoc(doc(answersRef, newId), {
        userId: getState().auth.auth.uid,
        projectId: project.id,
        formId: currentForm.id,
        values: jsonString,
        finished: false,
      });

      getAnswers();
    } catch (e) {
      console.error('Error adding document: ', e);
    }
    dispatch({ type: SAVE_ANSWER, payload: exampleForm });
  };
};
