// @ts-ignore
import React, { useEffect, useState } from 'react';
import { Navigate, Route } from 'react-router';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';

import Notification from '../components/notification/Notification';
import Register from '../pages/auth/Register';
import Login from '../pages/auth/Login';
import Reset from '../pages/auth/Reset';
import EditConfigs from '../pages/auth/EditConfigs';
import Projects from '../pages/protected/Projects';
import Project from '../pages/protected/Project';
// import { selectAuth } from '../redux/auth/Selector';
import Header from '../../src/components/shared/Header';
import PassPhrase from '../pages/auth/PassPhrase';
import PageNotFound from '../pages/NotFound';
import { getUser } from '../../src/redux/users/usersActions';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../src/configs/databaseConfig';
import { selectAuth } from '../../src/redux/auth/Selector';

function RequireAuth({ children }: { children: JSX.Element }) {
  // @ts-ignore
  const user = useSelector(getUser);

  // const user = localStorage.getItem('user');
  const location = useLocation();

  if (user) {
    return children;
  }
  return <Navigate to="/login" state={{ from: location }} replace />;
}

const ProtectedRoute = () => {
  const use = useSelector(selectAuth);
  const [currUser, setCurrUser] = useState({});
  useEffect(() => {
    onAuthStateChanged(auth, currentUser => {
      setCurrUser(currentUser);
    });
  }, []);
  console.log(currUser, 'current user');
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<RequireAuth>{use ? <Projects /> : <Login />}</RequireAuth>} />
          <Route
            path="/project/:id"
            element={
              <RequireAuth>
                <Project />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/editConfigs" element={<EditConfigs />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>

        <PassPhrase />
        <Notification />
      </Router>
    </div>
  );
};

export default ProtectedRoute;
