const firebaseConfig = {
  apiKey: 'AIzaSyCQddB3Z964wGw5CmpzFmsCKfyMI7_m-WI',
  authDomain: 'chamber-92187.firebaseapp.com',
  projectId: 'chamber-92187',
  databaseURL: 'https://chamber-92187-default-rtdb.asia-southeast1.firebasedatabase.app',
  storageBucket: 'chamber-92187.appspot.com',
  messagingSenderId: '851042141604',
  appId: '1:851042141604:web:05bd39aea6ecafdb7f6d31',
};

export default firebaseConfig;
