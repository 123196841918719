import { ADD_PROJECT, SAVE_PROJECT, GET_PROJECTS, CURRENT_PROJECT } from './Types';
// import { ADD_NOT } from '../notifications/Types';
import { collection, query, where, getDocs, doc, getDoc, deleteDoc, setDoc } from 'firebase/firestore';
// import { auth } from '../../configs/databaseConfig';

export const addProject = project => {
  return async dispatch => {
    dispatch({
      type: ADD_PROJECT,
      payload: project,
    });
  };
};

export const getProject = id => {
  return async (dispatch, getState) => {
    const database = getState().firebase.database;
    const docRef = doc(database, 'projects', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      dispatch({ type: CURRENT_PROJECT, payload: { ...docSnap.data(), id } });
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!');
    }
  };
};

export function getProjects() {
  return async (dispatch, getState) => {
    const database = getState().firebase.database;
    console.log(database, 'database');
    const projectsRef = collection(database, 'projects');
    const data = query(projectsRef, where('userId', '==', getState().auth.auth.uid));
    const querySnapshot = await getDocs(data);
    const events = [];

    querySnapshot.forEach(doc => events.push({ ...doc.data(), id: doc.id }));
    return dispatch({ type: GET_PROJECTS, payload: events });
  };
}

export const saveProject = () => {
  return async (dispatch, getState) => {
    const project = getState().project;
    const database = getState().firebase.database;
    const formsRef = collection(database, 'projects');
    dispatch(getProjects());
    try {
      const docRef = await setDoc(doc(formsRef), {
        userId: getState().auth.auth.uid,
        name: project.name,
        description: project.description,
        date: new Date(),
      });
    } catch (e) {
      console.log(e);
      // dispatch({ type: ADD_NOT, payload: e });
    }
    dispatch({ type: SAVE_PROJECT, payload: project });
  };
};

export const setCurrentProject = project => {
  return async dispatch => {
    dispatch({ type: CURRENT_PROJECT, payload: project });
  };
};

export const removeProject = projectId => {
  return async (dispatch, getState) => {
    console.log(projectId);
    const database = getState().firebase.database;
    deleteDoc(doc(database, 'projects', projectId));
    dispatch(getProjects());
  };
};
