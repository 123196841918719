import React from 'react';
import Modal from 'react-modal';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { removeProject } from '../../redux/project/projectAction';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const DeleteProjectConfirm = ({ confirmDelete, toggleDeleteModal, projectId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deleteProject = e => {
    dispatch(removeProject(e));
    const success = { code: t('deleteMessage') };
    dispatch({ type: 'ADD_NOTIFICATION', payload: success });
    toggleDeleteModal();
  };

  return (
    <Modal isOpen={confirmDelete} onRequestClose={toggleDeleteModal} contentLabel="My dialog">
      <div className="form-group last mb-4 field--not-empty">
        <p>{t('deleteConfirmation')}</p>
      </div>
      <button onClick={toggleDeleteModal} className="btn btn-cancel-delete">
        {t('cancel')}
      </button>
      <button onClick={() => deleteProject(projectId)} className="btn btn-delet-project">
        {t('delete')}
      </button>
      <IconButton onClick={toggleDeleteModal} className="modal-close-btn">
        <CloseIcon />
      </IconButton>
    </Modal>
  );
};

export default DeleteProjectConfirm;
