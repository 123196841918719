import { collection, doc, setDoc } from 'firebase/firestore';

// @ts-ignore
import Form1 from './forms/Form1.json';
// @ts-ignore
import Form2 from './forms/Form2.json';
// @ts-ignore
import Form3 from './forms/Form3.json';
// @ts-ignore
import Form4 from './forms/Form4.json';

import enForm1 from './local/en/Form1.json';
import enForm2 from './local/en/Form2.json';
import enForm3 from './local/en/Form3.json';
import enForm4 from './local/en/Form4.json';
import amForm1 from './local/am/Form1.json';
import amForm2 from './local/am/Form2.json';
import amForm3 from './local/am/Form3.json';
import amForm4 from './local/am/Form4.json';

const forms = [
  { form: Form1, name: 'form1', id: 'form1', name_en: 'Engagement setup', name_am: 'Առաջադրանքի նախապատրաստում' },
  {
    form: Form2,
    name: 'form2',
    id: 'form2',
    name_en: 'Audit strategy, Planning and Risk assessment',
    name_am: 'Աուդիտի ռազմավարություն, պլանավորում և ռիսկերի գնահատում',
  },
  { form: Form3, name: 'form3', id: 'form3', name_en: 'Testing', name_am: 'Թեստավորում' },
  { form: Form4, name: 'form4', id: 'form4', name_en: 'Completion', name_am: 'Ավարտում' },
];

const trForms = [
  { form: enForm1, name: 'form1', id: 'en_form1' },
  { form: enForm2, name: 'form2', id: 'en_form2' },
  { form: amForm1, name: 'form1', id: 'am_form1' },
  { form: amForm2, name: 'form2', id: 'am_form2' },
  { form: amForm3, name: 'form3', id: 'am_form3' },
  { form: enForm3, name: 'form3', id: 'en_form3' },
  { form: amForm4, name: 'form4', id: 'am_form4' },
  { form: enForm4, name: 'form4', id: 'en_form4' },
];

export async function migration(database) {
  const formsRef = collection(database, 'forms');
  forms.map(async (form, i) => {
    const name_en = form.name_en;
    const name_am = form.name_am;
    const date = {
      data: JSON.stringify(form.form),
      en_name: `${name_en}`,
      am_name: `${name_am}`,
      order: i,
    };
    return await setDoc(doc(formsRef, form.name), date);
  });
}

export async function translationsMigration(database) {
  const formsRef = collection(database, 'translations');
  trForms.map(async (form, i) => {
    console.log(form);
    const type = form['id'].slice(0, 2);
    const date = {
      data: JSON.stringify(form.form),
      name: `${form.name}`,
      order: i,
      lang: `${type}`,
    };
    return await setDoc(doc(formsRef, form.id), date);
  });
}
