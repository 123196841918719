import React, { useEffect } from 'react';
// @ts-ignore
import Inputs from '../../migrations/forms/Form2.json';
import FinalForm from '../../core/form/FinalForm';
import { saveAnswers, getProjectAnswer } from '../../redux/answers/AnswersAction';
import { selectInitialValues } from '../../redux/answers/Selector';
import { selectCurrentProject } from '../../redux/project/Selector';
import { selectTranslations } from '../../redux/translations/Selector';
import { selectCurrentForm } from '../../redux/forms/Selector';
import { useDispatch, useSelector } from 'react-redux';
import { fileDownload } from '../../redux/uploads/uploadsActions';
import Sidebar from '../../components/sidebar/Sidebar';
import Html2Pdf from 'js-html2pdf';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
function Form({ id, form, lang }) {
  const { t } = useTranslation();
  const initialValues = useSelector(selectInitialValues);
  const currentProject = useSelector(selectCurrentProject);
  const currentForm: any = useSelector(selectCurrentForm);
  const translations: any = useSelector(selectTranslations);
  const initialValue = initialValues?.[id]?.[0]?.values;
  const initialValueParsed = initialValue ? JSON.parse(initialValue) : {};
  const inputs = form && form.data && JSON.parse(form.data);
  const dispatch = useDispatch();
  const saveAnswerData = () => {
    const success = { code: 'Form saved' };
    dispatch({ type: 'ADD_NOTIFICATION', payload: success });
    dispatch(saveAnswers(id));
  };

  const printDocument = () => {
    dispatch(fileDownload(currentForm.id));

    const element = document.getElementById('divToPrint');

    const options = {
      filename: 'audit.pdf',
      html2canvas: { scale: 1, dpi: 100 },
    };

    const exporter = new Html2Pdf(element, options);
    exporter.getPdf(true);
  };

  useEffect(() => {
    if (currentProject && currentForm) {
      dispatch(getProjectAnswer());
    }
  }, [currentProject, currentForm]);

  let labels = [];
  translations?.map(item => {
    if (form.id == item.name) {
      labels = item;
    }
  });

  return (
    <Grid container spacing={2}>
      {id ? (
        <>
          <Grid item xs={3} className="sidebar">
            {/* @ts-ignore */}
            <Sidebar inputs={inputs || Inputs} labels={labels} />
          </Grid>
          <Grid item xs={9}>
            <a href={'/downloadFiles/' + lang + id + '.pdf'} className="info_button" download>
              <Tooltip title="Instructions" placement="right-start">
                <InfoOutlinedIcon />
              </Tooltip>
            </a>
            <div id="divToPrint">
              {id ? (
                <FinalForm inputs={inputs || Inputs} name={id} initialValues={initialValueParsed} labels={labels} />
              ) : null}
            </div>
            <div className="save-container save-pdf">
              <button onClick={saveAnswerData} className="btn-small btn-green btn text-white btn-block">
                {t('save')}
              </button>
              <button onClick={printDocument} className="btn-pdf btn text-white btn-block" id="scroll" />
            </div>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}
export default Form;
