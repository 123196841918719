import { CREATE_AUTH } from './Types';

export default function (state = {}, action) {
  switch (action.type) {
    case CREATE_AUTH:
      return { ...state, auth: action.payload };
    default:
      return state;
  }
}
