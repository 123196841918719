import FormStateToRedux from 'redux/form/FormStateToRedux';
import React from 'react';
import { Form } from 'react-final-form';
import { FormGenerator } from 'helpers/FormGeneratorFinal';

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const onSubmit = async values => {
  // await sleep(300);
  // @ts-ignore
  window.alert(JSON.stringify(values, 0, 2));
};

const AssistantForm = ({ initialValues, inputs, name, labels }) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormStateToRedux form={name} />
          {FormGenerator(inputs, labels)}
        </form>
      )}
    />
  );
};

export default AssistantForm;
