import { GET_UPLOADS } from './Types';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import axios from 'axios';
import { generateZip } from '../../helpers/jszip';

export const saveFile = (newFile, field) => {
  return async (dispatch, getState) => {
    const database = getState().firebase.database;
    const state = getState();
    try {
      await addDoc(collection(database, 'files'), {
        formId: state.forms.currentForm.id,
        projectId: state.project.current.id,
        fileName: newFile.fileName,
        fileUrl: newFile.fileUrl,
        field: field,
      });
      dispatch(getFormUploads());
    } catch (e) {
      dispatch({ type: 'ADD_NOTIFICATION', payload: e });
    }
  };
};

export function getFormUploads() {
  return async (dispatch, getState) => {
    const database = getState().firebase.database;
    const state = getState();
    const project = state.project.current;
    const currentForm = state.forms.currentForm;

    const filesRef = collection(database, 'files');
    try {
      const data = query(filesRef, where('projectId', '==', project.id), where('formId', '==', currentForm.id));
      const querySnapshot = await getDocs(data);
      const events = [];
      querySnapshot.forEach(doc => events.push({ ...doc.data(), id: doc.id }));

      return dispatch({ type: GET_UPLOADS, payload: events, id: currentForm.id });
    } catch (e) {
      dispatch({ type: 'ADD_NOTIFICATION', payload: { code: 'bbbb' } });
    }
  };
}

export const fileDownload = curentFormId => {
  return async (dispatch, getState) => {
    const uploads = getState().uploads.uploads.filter(item => {
      return item.formId === curentFormId;
    });

    const blobs = await Promise.all(
      uploads.map(file =>
        axios({
          url: file.fileUrl,
          method: 'GET',
          responseType: 'blob',
        }).then(response => {
          // const url = window.URL.createObjectURL(new Blob([response.data]));
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', file.fileName);
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);
          return {
            id: response.data.size,
            fileName: file.fileName,
            file: response.data,
          };
        })
      )
    );

    generateZip(blobs);
  };
};
