// QUACK! This is a duck. https://github.com/erikras/ducks-modular-redux

// Actions
const UPDATE_FORM_STATE = 'final-form-redux-forms/finalForm/UPDATE_FORM_STATE';
const RESET_FORM_STATE = 'final-form-redux-forms/finalForm/RESET_FORM_STATE';

// Reducer
export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case UPDATE_FORM_STATE:
      return {
        ...state,
        [action.form]: action.payload,
      };
    case RESET_FORM_STATE:
      return {
        ...state,
        [action.form]: {
          ...state[action.form],
          values: {},
        },
      };
    default:
      return state;
  }
}

// Action Creators
export const updateFormState = (form, state) => {
  return {
    type: UPDATE_FORM_STATE,
    form,
    payload: state,
  };
};

export const resetFormState = form => ({
  type: RESET_FORM_STATE,
  form,
});
// Selectors
export const getFormState = (state, form) => (state && state.form && state.form[form]) || {};
