// @ts-ignore
import React from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Link } from 'react-router-dom';
import '../../assets/scss/login.scss';
// @ts-ignore
import image from '../../assets/icons/image.svg';
import { useDispatch } from 'react-redux';
import { encodeCredentials } from '../../helpers/encodeConfigs';
import FirebaseForm from './FirebaseForm';
import { addUser, updateUser } from '../../redux/users/usersActions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FieldItem from './fildItem';
import * as Yup from 'yup';
import { loginYup } from './authValidation';
import { fierbaseYup } from './authValidation';
import { useTranslation } from 'react-i18next';
import InputLabel from '@material-ui/core/InputLabel';

const Registration: React.FC = () => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
    ...loginYup,
    ...fierbaseYup,
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState, setValue } = useForm(formOptions);
  const { errors } = formState;
  const auth = getAuth();

  const dispatch = useDispatch();
  const onSubmit = data => {
    signInWithEmailAndPassword(auth, data.username, data.password)
      .then(userCredential => {
        const userId = userCredential.user.uid;

        const firebaseCredentials = encodeCredentials(data);

        dispatch(updateUser(firebaseCredentials, userId));
        dispatch(addUser(firebaseCredentials));
        const success = { code: 'Updated successfuly' };
        dispatch({ type: 'ADD_NOTIFICATION', payload: success });
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        dispatch({ type: 'ADD_NOTIFICATION', payload: error });

        console.log('Error ocured: ', errorCode, errorMessage);
      });
  };
  return (
    <div>
      <div className="content">
        <div className="container">
          <div className="row main-content">
            <div className="col-md-6 contents left-side">
              <div className="row left-side-content">
                <div className="col-md-8">
                  <div>
                    <div className="mb-4">
                      <h3>{t('editConfigs')}</h3>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <InputLabel>{t('username')}</InputLabel>
                      <FieldItem
                        item={{ value: 'username', label: <span>{t('username')}</span> }}
                        register={register}
                        errors={errors}
                      />
                      <InputLabel>{t('password')}</InputLabel>
                      <FieldItem
                        item={{ value: 'password', label: <span>{t('password')}</span>, type: 'password' }}
                        register={register}
                        errors={errors}
                      />
                      <div className="empty-space"></div>
                      <FirebaseForm errors={errors} register={register} setValue={setValue} />
                      <InputLabel>{t('confirmPassword')}</InputLabel>
                      <FieldItem
                        item={{ value: 'passPhrase', label: <span>{t('passPhrase')}</span>, type: 'password' }}
                        register={register}
                        errors={errors}
                      />
                      <FieldItem
                        item={{
                          value: 'confirmPassPhrase',
                          label: <span>{t('confirmPassPhrase')}</span>,
                          type: 'password',
                        }}
                        register={register}
                        errors={errors}
                      />
                      <button type="submit" className="btn text-white btn-block btn-green">
                        {t('update')}
                      </button>
                    </form>
                  </div>
                  <Link to="/login"> {t('backToLogin')} </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-md-2 right-side">
              <img src={image} alt="Image" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
