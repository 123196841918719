import { GET_TRANSLATIONS } from './Types';

export default function (state = {}, action) {
  switch (action.type) {
    case GET_TRANSLATIONS:
      return {
        ...state,
        translations: action.payload,
      };
    default:
      return state;
  }
}
