import { UPDATE_USER_CONFIGS, DELETE_USER_CONFIGS } from './Types';

export default function (state = {}, action) {
  switch (action.type) {
    case UPDATE_USER_CONFIGS:
      return { ...state, userConfigs: action.payload };
    case DELETE_USER_CONFIGS:
      return { ...state, userConfigs: {} };
    default:
      return state;
  }
}
