import { SAVE_FILE, GET_UPLOADS } from './Types';

export default function (state = {}, action) {
  switch (action.type) {
    case SAVE_FILE:
      return { ...state, uploads: action.payload };
    case GET_UPLOADS:
      return { ...state, uploads: action.payload };
    default:
      return state;
  }
}
