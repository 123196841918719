// @ts-ignore
import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import '../../assets/scss/login.scss';
import { useTranslation } from 'react-i18next';
import i18n from '../../../src/i18n/config';

// @ts-ignore

const FirebaseForm = ({ errors, register, setValue }) => {
  const { t } = useTranslation();
  const filds = [
    {
      value: 'apiKey',
      label: 'apiKey',
    },
    {
      value: 'projectId',
      label: 'projectId',
    },
    {
      value: 'messagingSenderId',
      label: 'messagingSenderId',
    },
    {
      value: 'appId',
      label: 'appId',
    },
  ];

  const firebaseCredsCopy = async setValue => {
    const info = await navigator.clipboard.readText();
    const splitted = info.split(' ');
    if (info.includes('databaseURL') && info.includes('apiKey')) {
      const apiKey = await splitted[38].replaceAll('"', '').replaceAll('\r\n', '').replaceAll(',', '');
      const projectId = splitted[47].replaceAll('"', '').replaceAll('\r\n', '').replaceAll(',', '');
      const messagingSenderId = splitted[53].replaceAll('"', '').replaceAll('\r\n', '').replaceAll(',', '');
      const appId = splitted[56]
        .replaceAll('\r\n', '')
        .replaceAll('"', '')
        .replaceAll('}', '')
        .replaceAll(';', '')
        .replaceAll('//', '')
        .replaceAll(',', '');

      setValue('apiKey', apiKey);
      setValue('projectId', projectId);
      setValue('messagingSenderId', messagingSenderId);
      setValue('appId', appId);
    } else if (info.includes('apiKey')) {
      const apiKey = splitted[38].replaceAll('"', '').replaceAll('\r\n', '').replaceAll(',', '');

      const projectId = splitted[44]
        .replaceAll('\r\n', '')
        .replaceAll('"', '')
        .replaceAll('//"', '')
        .replaceAll(',', '');
      const messagingSenderId = splitted[50]
        .replaceAll('\r\n', '')
        .replaceAll('"', '')
        .replaceAll('}', '')
        .replaceAll(';', '')
        .replaceAll('//', '')
        .replaceAll('', '')
        .replaceAll(',', '');

      const appId = splitted[53]
        .replaceAll('\r\n', '')
        .replaceAll('"', '')
        .replaceAll('}', '')
        .replaceAll(';', '')
        .replaceAll('//', '');

      setValue('apiKey', apiKey);
      setValue('projectId', projectId);
      setValue('messagingSenderId', messagingSenderId);
      setValue('appId', appId);
    } else {
      alert(t('nothingCopied'));
    }
  };

  const pasteInfo = () => {
    firebaseCredsCopy(setValue);
  };

  return (
    <>
      <h3 className={i18n.language == 'am' ? 'pasteInfoText' : 'h3'}>{t('fillTheForm')} </h3>
      <div className={i18n.language == 'am' ? 'pasteButtonArm' : 'pasteButtonEng'} onClick={pasteInfo}>
        {t('pasteFirebaseConfs')}
      </div>

      {filds.map(item => {
        return (
          <>
            <InputLabel>{item.label}</InputLabel>
            <Input
              className="fireBaseInput"
              key={item.value}
              item={item}
              register={register}
              errors={errors}
              {...register(item.value, { required: true })}
            />
          </>
        );
      })}
    </>
  );
};

export default FirebaseForm;
