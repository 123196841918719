import React from 'react';
// import Example from '../../components/forms/Example';
import Projects from '../../components/projects/Projects';
import Footer from '../../components/shared/Footer';

const ProjectsPage = () => {
  return (
    <div>
      <Projects />
      <Footer />
      {/* <Example /> */}
    </div>
  );
};

export default ProjectsPage;
