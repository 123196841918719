import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Link } from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import '../../assets/scss/login.scss';
import image from '../../assets/icons/image.svg';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
const Reset = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const auth = getAuth();
  const dispatch = useDispatch();
  const handleReset = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        console.log('success');
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        dispatch({ type: 'ADD_NOTIFICATION', payload: error });
        console.log('An error has occured: ', errorCode, errorMessage);
      });
  };
  return (
    <div>
      <div className="content">
        <div className="container">
          <div className="row main-content">
            <div className="col-md-6 contents left-side">
              <div className="row left-side-content">
                <div className="col-md-8">
                  <div>
                    <div className="mb-4">
                      <h3>{t('resetPassword')}</h3>
                    </div>
                    <div className="form-group first field--not-empty">
                      <InputLabel htmlFor="username">{t('username')}</InputLabel>
                      <Input
                        value={email}
                        id="username"
                        type="text"
                        className="form-control"
                        onChange={e => setEmail(e.target.value)}
                      />
                    </div>
                    <button onClick={handleReset} className="btn text-white btn-block btn-green">
                      {t('resetPassword')}
                    </button>
                  </div>
                  <Link to="/login">
                    <button type="submit" className="btn text-white btn-block btn-green">
                      {t('backToLogin')}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-md-2 right-side">
              <img src={image} alt="Image" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;
