import React from 'react';
import icons from 'assets/icons/icons.svg';

export function UseSvgIcon(icon, onClickIcon, className) {
  return onClickIcon ? (
    <svg style={{ cursor: 'pointer' }} className={`${className ? className : ''} icon ${icon}`} onClick={onClickIcon}>
      <use xlinkHref={`${icons}#${icon}`} />
    </svg>
  ) : (
    <svg className={`${className ? className : ''} icon ${icon}`}>
      <use xlinkHref={`${icons}#${icon}`} />
    </svg>
  );
}
