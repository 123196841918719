import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { selectDatabase, selectDatabaseConfig } from '../../redux/firebase/Selector';
import { decodeConfigs } from '../../redux/firebase/firebaseActions';
import { useTranslation } from 'react-i18next';
import { getUser } from '../../../src/redux/users/usersActions';
import { selectAuth } from '../../../src/redux/auth/Selector';
import { useNavigate } from 'react-router-dom';

const ProjectModal = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pass, setPass] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const firebase = useSelector(selectDatabase);
  const use = useSelector(selectAuth);
  const databaseConfig = useSelector(selectDatabaseConfig);
  const auth = useSelector(getUser);
  // const auth = localStorage.getItem('user');
  const handleProject = () => {
    dispatch(decodeConfigs(pass));
  };

  useEffect(() => {
    // @ts-ignore
    if (
      window.location.href != 'http://localhost:3005/login' &&
      window.location.href != 'http://localhost:3005/register' &&
      window.location.href != 'https://app.caaa.am/login' &&
      window.location.href != 'https://app.caaa.am/register' &&
      auth &&
      (!firebase || firebase.apps?.length === 0)
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [firebase, databaseConfig]);

  const changePass = e => {
    setPass(e.target.value);
  };

  const toggleModal = e => {
    setIsOpen(!isOpen);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={toggleModal} contentLabel="My dialog">
      <div className="form-group first field--not-empty">
        <InputLabel htmlFor="name">{t('passPhrase')}</InputLabel>
        <Input value={pass} id="name" type="password" className="form-control" onChange={changePass} />
      </div>
      <button onClick={handleProject} className="btn text-white btn-block btn-green ">
        Ok
      </button>
      <IconButton onClick={toggleModal} className="modal-close-btn">
        <CloseIcon />
      </IconButton>
    </Modal>
  );
};

export default ProjectModal;
