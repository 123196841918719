// @ts-ignore
import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/scss/login.scss';
// @ts-ignore
import image from '../../assets/icons/image.svg';
import { useDispatch } from 'react-redux';
import { getUser } from '../../redux/users/usersActions';
import { decodeConfigs } from '../../redux/firebase/firebaseActions';
import { auth } from '../../configs/databaseConfig';
import FieldItem from './fildItem';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { loginYup } from './authValidation';
import { checkDatabaseConnection } from '../../helpers/checkConnection';
import { logOut } from './LogOut';
import { useTranslation } from 'react-i18next';
import VisibilityPass from './passVisibility';
import InputLabel from '@material-ui/core/InputLabel';
import LoginIcon from '@mui/icons-material/Login';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PasswordIcon from '@mui/icons-material/Password';
import EditIcon from '@mui/icons-material/Edit';

const Login = () => {
  const [loginPassVis, setLoginPassVis] = useState(false);
  const [passPhraseVis, setPassPhraseVis] = useState(false);
  const [currUser, setCurrUser] = useState({});
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    ...loginYup,
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, currentUser => {
      setCurrUser(currentUser);
      console.log(currentUser, 'currentUser');
      if (!currentUser) {
        navigate('/login');
      }
    });
  }, []);

  const handleClick = () => {
    setLoginPassVis(!loginPassVis);
  };

  const handlePassPhraseVis = () => {
    setPassPhraseVis(!passPhraseVis);
  };

  const handleLogin = async data => {
    signInWithEmailAndPassword(auth, data.username, data.password)
      .then(authUser => {
        if (authUser) {
          getUserCredentials(auth.currentUser.uid);
        } else {
          navigate('/login');
        }
      })
      .catch(error => {
        dispatch({ type: 'ADD_NOTIFICATION', payload: error });
      });

    const getUserCredentials = async uId => {
      const userData: any = await dispatch(getUser(uId));
      console.log('getting data id ' + uId);
      const connected = checkDatabaseConnection(data.passPhrase, userData.payload);
      if (connected) {
        dispatch(decodeConfigs(data.passPhrase));
        navigate('/');
      } else {
        navigate('/login');
        const error = { code: 'PassPhrase is wrong' };
        dispatch({ type: 'ADD_NOTIFICATION', payload: error });
        logOut();
      }
    };
  };

  return (
    <div>
      <div className="content">
        <div className="container">
          <div className="row main-content">
            <div className="col-md-6 contents left-side">
              <div className="row left-side-content">
                <div className="col-md-8">
                  <div>
                    <div className="mb-4">
                      <h3>{t('signIn')}</h3>
                    </div>
                    <form onSubmit={handleSubmit(handleLogin)}>
                      <InputLabel>{t('username')}</InputLabel>
                      <FieldItem item={{ value: 'username' }} register={register} errors={errors} />
                      <InputLabel>{t('password')}</InputLabel>
                      <FieldItem
                        item={{
                          value: 'password',
                          type: loginPassVis ? 'text' : 'password',
                        }}
                        register={register}
                        errors={errors}
                      />

                      <VisibilityPass handleClick={handleClick} />
                      <InputLabel>{t('passPhrase')}</InputLabel>
                      <FieldItem
                        item={{
                          value: 'passPhrase',
                          type: passPhraseVis ? 'text' : 'password',
                        }}
                        register={register}
                        errors={errors}
                      />

                      <VisibilityPass handleClick={handlePassPhraseVis} />

                      <div className="d-flex mb-5 align-items-center">
                        <span className="caption">{t('rememberMe')}</span>
                        <input type="checkbox" />
                        <div className="control__indicator" />
                        <span className="ml-auto">
                          <br />
                          <Link to="/reset" className="forgot-pass">
                            {t('forgotPassword')}
                          </Link>
                          <PasswordIcon className="log-out-icon" />
                        </span>
                        <div>
                          <span className="ml-auto">
                            <Link to="/editConfigs" className="edit-configs">
                              {t('editConfigs')}
                            </Link>
                            <EditIcon className="log-out-icon" />
                          </span>
                        </div>
                      </div>

                      <button type="submit" className="btn text-white btn-block btn-green log-in-icon">
                        {t('logIn')}
                        <LoginIcon className="log-out-icon" />
                      </button>
                    </form>
                  </div>
                  <Link className="linkStyle" to="/register">
                    <button className="btn text-white btn-block btn-green log-in-icon">
                      {t('register')}
                      <HowToRegIcon className="log-out-icon" />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 order-md-2 right-side">
              <img src={image} alt="Image" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
