import React from 'react';
import PropTypes from 'prop-types';

function RadioOptions(props) {
  const {
    input,
    field,
    titles,
    meta: { touched, error, warning },
  } = props;
  const name = input.name;
  const value = input.value || [];
  const options = field.props.options;
  const change = e => {
    if (field.multi) {
      let result = [...value];
      if (Array.isArray(result)) {
        const index = result.findIndex(elem => elem.value === e.value);
        if (index !== -1) {
          result.splice(index, 1);
        } else {
          result.push(e);
        }
      } else {
        result = e;
      }
      input.onChange(result);
    } else return input.onChange(e);
  };

  return (
    <div className={`mb-0 ${field.stylePageBreak && field.stylePageBreak}`}>
      <div className="d-flex flex-direction-row justify-content-around radio-options">
        {titles[`${name}_header`] && <p className="mr-4">{titles[`${name}_header`]}</p>}
        {titles[`${name}_label`] && <p className="mr-3">{titles[`${name}_label`]} </p>}

        <div className="d-flex row justify-content-end write-answer">
          {options &&
            options.map((item, i) => {
              return (
                <div key={item.value} className="mr-2">
                  <input
                    type={field.multi ? 'checkbox' : 'radio'}
                    className="form-check-input"
                    id={`${input.name}${i}`}
                    name={`${input.name}${i}`}
                    value={item}
                    checked={
                      item.value === value.value ||
                      (field.multi && Array.isArray(value) && value.find(elem => elem.value === item.value))
                    }
                    onChange={() => change(item)}
                  />
                  <label htmlFor={`${input[name]}${i}`}>{titles[options[i]['label']]}</label>
                </div>
              );
            })}
        </div>
      </div>

      {touched &&
        ((error && <p className="help-block">{error}</p>) || (warning && <p className="help-block">{warning}</p>))}
    </div>
  );
}
RadioOptions.propTypes = {
  input: PropTypes.object,
  field: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.array,
};
export default RadioOptions;
