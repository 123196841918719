// @ts-ignore
// eslint-disable react/prop-types
import React from 'react';

function Description(props) {
  const { field, titles } = props;
  const name = field.name;
  return (
    <div className={`mb-3 ${field.stylePageBreak && field.stylePageBreak}`} id={field.name}>
      <div className="d-flex flex-direction-row justify-content-around write-answer">
        {titles[`${name}_header`] && <p className="main-title">{titles[`${name}_header`]} </p>}
        {titles[`${name}_subTitle`] && <p className="sub-title">{titles[`${name}_subTitle`]} </p>}
        {titles[`${name}_subSubTitle`] && <p className="sub-title">{titles[`${name}_subSubTitle`]} </p>}
        {titles[`${name}_boldText`] && <p className="mr-4">{titles[`${name}_boldText`]} </p>}
        {titles[`${name}_text`] && <p className="sub-title">{titles[`${name}_text`]} </p>}
        {titles[`${name}_Description`] && <p className="mr-3">{titles[`${name}_Description`]} </p>}
      </div>
    </div>
  );
}

export default Description;
