import JSZip from 'jszip';
import { saveAs } from 'file-saver';

var zip = JSZip();

const download = () => {
  zip.generateAsync({ type: 'blob' }).then(function (blob) {
    saveAs(blob, 'files.zip');
  });
};

export const generateZip = arr => {
  if (arr.length) {
    for (const element of arr) {
      zip.file(element.fileName, element.file, {
        binary: true,
      });
    }

    download();
  }
};
