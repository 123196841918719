import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotification } from '../../redux/notifications/Selector';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function Notification() {
  const dispatch = useDispatch();
  const notification = useSelector(selectNotification);
  const [key, setKey] = useState(1);
  const newKey = () => setKey(key + 1);
  useEffect(newKey, [notification]);

  const handleClose = () => {
    dispatch({ type: 'ADD_NOTIFICATION' });
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  // @ts-ignore
  return notification && notification.code ? (
    <Snackbar
      open={!!notification}
      autoHideDuration={6000}
      onClose={handleClose}
      // @ts-ignore
      message={`${notification.code || ' '} : ${notification.message || ' '}`}
      action={action}
    />
  ) : null;
}

export default Notification;
