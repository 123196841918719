import { ADD_PROJECT, SAVE_PROJECT, CURRENT_PROJECT, GET_PROJECTS } from './Types';

export default function (state = {}, action) {
  switch (action.type) {
    case ADD_PROJECT:
      return {
        ...state,
        ...action.payload,
      };
    case SAVE_PROJECT:
      return { ...state };
    case CURRENT_PROJECT:
      return { ...state, current: action.payload };
    case GET_PROJECTS:
      return { ...state, projects: action.payload };
    default:
      return state;
  }
}
