import { secrets } from '../configs/secrets';

export const checkDatabaseConnection = (cipher, userData) => {
  // const case1 = /^[A-Za-z0-9]*$/.test(secrets.decode(cipher, userData.apiKey));
  const case2 = /^[0-9]+$/.test(secrets.decode(cipher, userData.messagingSenderId));
  const case3 = secrets.decode(cipher, userData.appId).includes(':web:');
  if (case2 && case3) {
    return true;
  } else {
    return false;
  }
};
