import { collection, query, getDocs, where } from 'firebase/firestore';
export function getTranslation(props) {
  return async (dispatch, getState) => {
    const lang = props;
    const database = getState().firebase.database;

    const projectsRef = collection(database, 'translations');
    const data = query(projectsRef, where('lang', '==', `${lang}`));

    const querySnapshot = await getDocs(data);
    const events = [];

    querySnapshot.forEach(doc => events.push({ ...doc.data(), id: doc.id }));
    return dispatch({ type: 'GET_TRANSLATIONS', payload: events });
  };
}
