import { CREATE_FIERBASE } from './Types';

export default function (state = {}, action) {
  switch (action.type) {
    case CREATE_FIERBASE:
      return { ...state, databaseConfig: action.databaseConfig, database: action.database, storage: action.storage };
    default:
      return state;
  }
}
