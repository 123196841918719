import React from 'react';
import PropTypes from 'prop-types';
import 'quill/dist/quill.snow.css';
import Select from 'react-select';

function SelectInput(props) {
  const {
    input,
    field,
    options,
    meta: { touched, error, warning },
  } = props;
  const blur = () => {};

  const change = e => {
    input.onChange(e);
  };

  return (
    <div className={`select ${field.class && field.class} ${field.stylePageBreak && field.stylePageBreak}`}>
      <div className="d-flex flex-direction-row justify-content-around radio-options">
        <label htmlFor="inputWarning2i">{field.label}</label>

        <Select
          {...input}
          value={input.value}
          onChange={change}
          name="form-field-name2"
          options={options}
          isMulti={field.multi}
          onBlur={blur}
          className={field.style}
        />

        {touched &&
          ((error && <p className="help-block">{error}</p>) || (warning && <p className="help-block">{warning}</p>))}
      </div>
    </div>
  );
}
SelectInput.propTypes = {
  input: PropTypes.object,
  field: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.array,
};
export default SelectInput;
