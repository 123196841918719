import React from 'react';

function PageNotFound() {
  return (
    <>
      <h1>Not Found :(</h1>
    </>
  );
}

export default PageNotFound;
