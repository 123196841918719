// @ts-ignore
import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import '../../assets/scss/login.scss';
// @ts-ignore
import image from '../../assets/icons/image.svg';
import { useDispatch } from 'react-redux';
import { encodeCredentials } from '../../helpers/encodeConfigs';
import FieldItem from './fildItem';
import { addUser, createUser } from '../../redux/users/usersActions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { loginYup } from './authValidation';
import { fierbaseYup } from './authValidation';
import { confirmPasswordYup } from './authValidation';
import { useTranslation } from 'react-i18next';
import VisibilityPass from './passVisibility';
import InputLabel from '@material-ui/core/InputLabel';
import FirebaseForm from './FirebaseForm';

const Registration: React.FC = () => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    ...loginYup,
    ...confirmPasswordYup,
    ...fierbaseYup,
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const [isRegisterPassVisible, setIsRegisterPassVisible] = useState(false);
  const [isRegisterPassPhraseVisible, setIsRegisterPassPhraseVisible] = useState(false);
  const { register, handleSubmit, formState, setValue } = useForm(formOptions);
  const { errors } = formState;
  const navigate = useNavigate();
  const auth = getAuth();
  const dispatch = useDispatch();

  const onSubmit = data => {
    createUserWithEmailAndPassword(auth, data.username, data.password)
      .then(userCredential => {
        const user = userCredential.user;
        const firebaseCredentials = encodeCredentials(data);
        dispatch(createUser(firebaseCredentials));
        dispatch(addUser(firebaseCredentials));
        const success = { code: 'Created successfully' };
        dispatch({ type: 'ADD_NOTIFICATION', payload: success });
        return navigate('/login');
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        dispatch({ type: 'ADD_NOTIFICATION', payload: error });
        console.log('Error ocured: ', errorCode, errorMessage);
      });
  };

  const handleRegisterPassVis = () => {
    setIsRegisterPassVisible(!isRegisterPassVisible);
  };

  const handleRegisterPassPhrase = () => {
    setIsRegisterPassPhraseVisible(!isRegisterPassPhraseVisible);
  };

  // @ts-ignore
  return (
    <div>
      <div className="content">
        <div className="container">
          <div className="row main-content">
            <div className="col-md-6 contents left-side">
              <div className="row left-side-content">
                <div className="col-md-8">
                  <div>
                    <div className="mb-4">
                      <h3>{t('register')}</h3>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <InputLabel>{t('username')}</InputLabel>
                      <FieldItem item={{ value: 'username' }} register={register} errors={errors} />
                      <InputLabel>{t('password')}</InputLabel>
                      <FieldItem
                        item={{
                          value: 'password',
                          type: isRegisterPassVisible ? 'text' : 'password',
                        }}
                        register={register}
                        errors={errors}
                      />

                      <VisibilityPass handleClick={handleRegisterPassVis} />
                      <InputLabel>{t('confirmPassword')}</InputLabel>
                      <FieldItem
                        item={{
                          value: 'confirmPassword',
                          type: isRegisterPassVisible ? 'text' : 'password',
                        }}
                        register={register}
                        errors={errors}
                      />
                      <VisibilityPass handleClick={handleRegisterPassVis} />
                      <div className="empty-space" />
                      <a
                        className="register-link forgot-pass"
                        target="_blank"
                        rel="noreferrer"
                        href="https://firebase.google.com/"
                      >
                        {t('registerFirebase')}
                      </a>
                      <div className="empty-space" />
                      <FirebaseForm errors={errors} register={register} setValue={setValue} />

                      <div className="marginBottom"></div>
                      <InputLabel>{t('passPhrase')}</InputLabel>
                      <FieldItem
                        item={{
                          value: 'passPhrase',
                          type: isRegisterPassPhraseVisible ? 'text' : 'password',
                        }}
                        register={register}
                        errors={errors}
                      />

                      <VisibilityPass handleClick={handleRegisterPassPhrase} />
                      <InputLabel>{t('confirmPassPhrase')}</InputLabel>
                      <FieldItem
                        item={{
                          value: 'confirmPassPhrase',
                          type: isRegisterPassPhraseVisible ? 'text' : 'password',
                        }}
                        register={register}
                        errors={errors}
                      />
                      <div className="passPhraseConf">
                        <VisibilityPass handleClick={handleRegisterPassPhrase} />
                      </div>
                      <button type="submit" className="btn text-white btn-block btn-green">
                        {t('register')}
                      </button>
                    </form>
                  </div>
                  <Link to="/login">
                    <button className="btn text-white btn-block btn-green">{t('backToLogin')}</button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-6 order-md-2 right-side">
              <img src={image} alt="Image" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
