import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import '../../assets/scss/login.scss';

export default function Language() {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [language, setLanguage] = useState('Հայ');

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = lang => {
    i18n.changeLanguage(lang);
    if (lang === 'en') {
      setLanguage('Eng');
    } else {
      setLanguage('Հայ');
    }
  };

  return (
    <div className="languageClass">
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {language}
      </Button>
      <div className="language-menu">
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleClose}>
            <div className="language-style">
              <span onClick={() => changeLanguage('en')}>Eng</span>
              <span onClick={() => changeLanguage('am')}>Հայ</span>
            </div>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}
