import React from 'react';
import { Field } from 'react-final-form';
//Form Components
import { TextInput } from 'core/form/filds';
import Select from 'core/form/Select';
import RadioOptions from 'core/form/RadioOptions';
import TextArea from 'core/form/TextArea';
import Description from 'core/form/Description';
//import Table from 'core/form/Table';
import DatePicker from 'core/form/DatePicker';
import DynamicTable from 'core/form/DynamicTable';
import FileUpload from 'core/form/FileUpload';

export function FormGenerator(inputs, labels) {
  const formFiled = [];
  const titles = labels && labels.data && JSON.parse(labels.data);
  inputs &&
    Array.isArray(inputs) &&
    inputs.map(input => {
      const { name, component } = input;
      let eachComponent;
      switch (component) {
        case 'FileUpload':
          eachComponent = FileUpload;
          break;
        case 'TextArea':
          eachComponent = TextArea;
          break;
        case 'Description':
          eachComponent = Description;
          break;
        case 'Table':
          eachComponent = DynamicTable;
          break;
        case 'DynamicTable':
          eachComponent = DynamicTable;
          break;
        case 'select':
          eachComponent = Select;
          break;
        case 'radioOptions':
          eachComponent = RadioOptions;
          break;
        case 'datePicker':
          eachComponent = DatePicker;
          break;
        default:
          eachComponent = TextInput;
      }

      if (input.type === 'group') {
        formFiled.push(
          <div className="group" key={input.label}>
            <h5>{input.label}</h5>
            <div>
              {input.fields &&
                Array.isArray(input.fields) &&
                input.fields.map(elem => {
                  // eslint-disable-next-line prettier/prettier
                  return (
                    <Field
                      key={elem.name}
                      name={elem.name}
                      component={eachComponent}
                      field={elem}
                    />
                  );
                })}
            </div>
          </div>
        );
      } else {
        formFiled.push(
          // eslint-disable-next-line prettier/prettier
          <Field
            key={input.name}
            name={name}
            component={eachComponent}
            field={input}
            titles={titles || []}
          />
        );
      }
      return name;
    });
  return formFiled;
}
