import { ADD_NOT, CLEAR_NOT } from './Types';

export const Notification = (state = {}, action) => {
  switch (action.type) {
    case ADD_NOT:
      return {
        ...state,
        notification: action.payload,
      };
    case CLEAR_NOT:
      return {
        ...state,
      };
    default:
      return state;
  }
};
