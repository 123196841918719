import React from 'react';

function Information({ info }) {
  return (
    <div className="more-info">
      <p>{info}</p>
    </div>
  );
}

export default Information;
