import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { getForms, setCurrentTab } from '../../redux/forms/FormsAction';
import { getTranslation } from '../../redux/translations/TranslationsAction';
import { selectForms, selectCurrentForm } from '../../redux/forms/Selector';
import { selectDatabase } from '../../redux/firebase/Selector';
import { useDispatch, useSelector } from 'react-redux';
import Form from './Form';
import { useTranslation } from 'react-i18next';

export default function FormTabs() {
  const dispatch = useDispatch();
  const forms: any[] = useSelector(selectForms);
  const currentForm: any = useSelector(selectCurrentForm);
  const database = useSelector(selectDatabase);
  const id = currentForm?.id;
  const { i18n } = useTranslation();
  const lang = i18n.language;
  useEffect(() => {
    if (!forms) {
      dispatch(getForms());
      return;
    }
    dispatch(getTranslation(lang));
    if (!currentForm) {
      handleSetCurrent(forms[0]);
    }
  }, [forms, database, lang]);

  const handleSetCurrent = e => {
    dispatch(setCurrentTab(e));
  };

  return (
    <>
      <AppBar position="sticky" className="tabs">
        <Tabs value={id}>
          {forms?.map(form => {
            return (
              <Tab value={form.id} key={form.id} label={form[`${lang}_name`]} onClick={() => handleSetCurrent(form)} />
            );
          })}
        </Tabs>
      </AppBar>
      <div>
        <Form form={currentForm} id={id} lang={lang} />{' '}
      </div>
    </>
  );
}
